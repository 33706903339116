import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ERole } from 'src/app/core/enums/roles.enum';
import { ImpersonateService } from 'src/app/core/services/impersonate.service';
import { RoleService } from 'src/app/core/services/roles.service';
import { UserService } from 'src/app/core/services/user.service';
import { MessageDialogComponent } from 'src/app/dialog/message-dialog/message-dialog.component';
import { EStatus } from 'src/app/shared/enums/status.enum';
import { IHello } from 'src/app/shared/models/hello.model';
import { IBasePerson } from 'src/app/shared/models/person.model';
import { environment } from 'src/environments/environment';
import { SharedModule } from "../../../shared/shared.module";

@Component({
    selector: 'app-admin-header',
    standalone: true,
    templateUrl: './admin-header.component.html',
    styleUrls: ['./admin-header.component.scss'],
    imports: [CommonModule, SharedModule, RouterModule]
})
export class AdminHeaderComponent implements OnInit {
    @ViewChild(MessageDialogComponent)
    protected messageDialogComponent: MessageDialogComponent;	
    
    public user: IHello;
    public currentUser: IBasePerson | undefined;
    protected isDeveloper = false;  
    protected isAdmin = false;
    protected hasConfigAuthority = false;
    protected isImpersonating = false;
    public title = '';
    public username: string | undefined = '';
    public pendingStatusCode: string = EStatus.PENDING.toString();
    public pendingInterviewStatusCode: string = EStatus.INTERVIEW_REQUESTED.toString();
    public pendingFinalDecisionStatusCode: string = EStatus.FINAL_DECISION.toString();

    protected logoutUrl =  `${environment.apiUrl}/logout`

    constructor(
        private roleService: RoleService,
        private router: Router,
        private userService: UserService,
        private impersonateService: ImpersonateService,
      ){}

      ngOnInit(): void {
        this.isAdmin = this.roleService.hasRole(ERole.SHEPHERDS_ADMIN);
        this.hasConfigAuthority = this.roleService.hasRole(ERole.SHEPHERDS_CONFIG);
        this.title = this.isAdmin ? 'ADMIN' : '';
        
        this.isDeveloper = this.roleService.hasAnyRole([ERole.SHEPHERDS_SWITCH_USER]);
        this.userService.helloUser().subscribe(user => {
          if (user.currentUser != null) {
            this.user = user; 
            this.currentUser = user.currentUser
            this.username = user.currentUser.printName;
            this.isImpersonating = user.impersonatingUser != null;
          }
        });
      }
    
      protected openImpersonateModal(): void {
        this.impersonateService.openImpersonateModal();
      }
    
      protected stopImpersonating(): void {
        this.impersonateService.stopImpersonating();
      }
}
