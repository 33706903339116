import { Component, Input } from '@angular/core';
import { IDialogData } from '../models/dialog-data.model';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {

  @Input()
  public toastLike = false;

  @Input()
  public timeout: number;

  protected title: string;
  protected message: string | undefined;

  public isOpen = false;

  public open(data: IDialogData): void {
    this.title = data.title;
    this.message = data.message;
    this.isOpen = true;
    if(this.timeout){
      setTimeout(() => {
        this.close();
      }, this.timeout);
    }
    if(this.toastLike) return;
      document.getElementsByTagName("body")[0].style['overflow'] = "hidden";
  }

  public close(): void {
    this.isOpen = false;
    if(this.toastLike) return;
    document.getElementsByTagName("body")[0].style['overflow'] = "initial";
  }
}