import { PopupMessageConfig } from './popup-message-config';


/**
 * Specifies that popups should be shown for http errors when they occur, optionally with specific instructions on how
 * to handle specific response codes, and/or with exempt http error response codes that should NOT have a popup shown.
 *
 * Use the popupMessageConfigs property to override default popup content for specific error codes
 * Use the suppressPopupsForCodes property to prevent a popup from displaying for specific error code(s) - It's only
 * recommended to do this when you plan to handle the error inline, i.e. instead of displaying a popup, error text is
 * displayed somewhere on your component for that specific situation.
 *
 * Note: If the same statusCode is in a provided PopupMessageConfig as well as in the suppressPopupsForCodes field, the
 * suppression will take precedence.
 *
 * It is not recommended to include more than 1 PopupMessageConfig per http response status code, as which one gets
 * resolved would be indeterminate, as order of evaluation is not guaranteed.
 *
 * @property suppressPopupsForCodes (Optional) the http error response codes that should be ignored by the popup error
 *           handler
 * @property popupMessageConfigs (Optional) the configurations for what to display in the popup for different http
 *           error response situations
 * @see PopupMessageConfig
 */
export class PopupHttpErrorsConfig {
  public popupMessageConfigs?: PopupMessageConfig[] = [];
  public suppressPopupsForCodes?: Array<number> = [];
}