import { ERole } from './roles.enum';

/**
 * Keeps logical and/or commonly used together ERoles as arrays in convenience static immutable fields.
 */
export class RoleGroup {
  public static readonly STUDENT_SHEPHERDS = [
    ERole.CURRENT_RS,
    ERole.FUTURE_RS
  ];
}