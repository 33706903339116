import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ERole } from "../enums/roles.enum";

@Injectable({
    providedIn: 'root'
  })
  export class RoleRepository {
  
    private readonly rolesUrl: string = `${environment.apiUrl}/roles.json`;
  
    constructor(private http: HttpClient) { }
  
    public getRoles(): Observable<Array<ERole>> {
      return this.http.get<Array<ERole>>(this.rolesUrl);
    }
  }