import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../loading.service';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable({providedIn: 'root'})
export class LoadingInterceptor implements HttpInterceptor {

  constructor(private loadingService: LoadingService) {}

  /* configure these RegExp for each route that is excluded from the loading spinner */
  private readonly endpointsToIgnore: {requestMethod: string[], regExp: RegExp}[] = [
    // left these in because maybe Shepherds has some to hide to?
    // {requestMethod: ['ANY'], regExp: new RegExp(/\/schedules\/poll/)},
    // {requestMethod: ['ANY'], regExp: new RegExp(/\/schedules\/\d{6}\/(add|drop|waitlist)/)},
    // {requestMethod: ['ANY'], regExp: new RegExp(/\/faculty/)},
    // {requestMethod: ['PUT'], regExp: new RegExp(/\/cart-items\/\d/)},
    // {requestMethod: ['ANY'], regExp: new RegExp(/\/dcp\/audit/)},
    // {requestMethod: ['ANY'], regExp: new RegExp(/\/dcp\/cart/)},
    // {requestMethod: ['ANY'], regExp: new RegExp(/\/who-am-i/)}
  ];

  //regex to identify a polling url ending in the format /poll?id=audit:{uuid}
  private readonly pollEndpointRegex = new RegExp(/\/poll\?id=[a-zA-Z0-9-_]+:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12}$/);

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /* compare request.url to each value in endpointsToIgnore */
    const passRequestThrough = this.inExclusionList(request);

    if (passRequestThrough) {
      return next.handle(request);
    }

    //if the request url looks like a poll endpoint, then we don't increment
    if (!this.pollEndpointRegex.test(request.url)) {
      this.loadingService.incrementPending(request.url);
    }

    return next.handle(request)
      .pipe(
        finalize(() => {
          this.loadingService.decrementPending(request.url);
        })
      );
  }

  private inExclusionList(request: HttpRequest<any>): boolean {
    return this.endpointsToIgnore.some(combo => {
      let match = false;
      if (combo.requestMethod.some(header => header === 'ANY')) {
        match = !!request.url.match(combo.regExp);
      } else {
        match = combo.requestMethod.some(type => {
          return !!request.url.match(combo.regExp) && request.method === type;
        });
      }

      /* Left these console statements because they are useful when you need to add a new endpoint to exclude */
      // console.groupCollapsed(`request url`, request.url);
      // console.log(`MATCH [${match}] - RegExp: [${combo.regExp}]`);
      // console.log(`request method [${request.method}]`)
      // console.groupEnd();

      /* if found, pass through (ie. do nothing) */
      return match;
    });
  }
}
