import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-lu-icon',
  templateUrl: './lu-icon.component.html',
  styleUrls: ['./lu-icon.component.scss']
})
export class LuIconComponent {
  @Input()
  public icon!: string;

  @Input()
  @HostBinding('class.flipped')
  public flipped = false;
}
