import { Component, Input } from '@angular/core';
import { IMenuOption } from '../../models/menu-option.model';

@Component({
  selector: 'app-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss']
})
export class MenuDropdownComponent {

    @Input()
    public username: string | undefined;

    @Input()
    public menuOptions: Array<IMenuOption> = [];

    protected showMenu = false;

    toggleDropdown() {
        this.showMenu = !this.showMenu;
    }

    clickedOutside(): void {
        this.showMenu = false;
    }

    tabbedOutside(): void {
        this.showMenu = false;
    }
}
