import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { MessageDialogComponent } from "src/app/dialog/message-dialog/message-dialog.component";
import { ImpersonateModalComponent } from "src/app/shared/components/impersonate/impersonate-modal.component";
import { IHello } from "src/app/shared/models/hello.model";
import { DialogService } from "./modal-service";
import { UserService } from "./user.service";


@Injectable({providedIn: 'root'})
export class ImpersonateService {

  private luidRegex = new RegExp(/^L\d{8}$/);
  private integersRegex = new RegExp(/^\d+$/);

  private messageDialogComponent: MessageDialogComponent;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: DialogService,
  ) { }

  /**
   * Function to open the impersonate modal to begin the
   * impersonate processes.
  */
  public openImpersonateModal(): void {
    const dialogRef = this.dialog.open(ImpersonateModalComponent, {data: {title: 'Title', message: 'Hello'}});
    dialogRef.afterClosed.subscribe(result => {
      if (result == null) return;

      this.impersonate(result);
    })
  }

  /**
   * Function to initiate the impersonate process triggered through the url, which can be called from other classes, e.g., RouterModule.
  */
  private impersonate(user: string) {
    return forkJoin([this.userService.helloUser(), this.userService.whoAmI()]).subscribe(([hello, whoAmI]: [IHello, string]) => {
      // TODO: Why do we need to stop impersonating when the backend handles all the role checking, authentications, etc...???
      // I.E if one removes the stop impersonate and just switches user via impersonating again it works fine.
      if (whoAmI != (hello.impersonatingUser || hello.currentUser).username) {
        this.userService.stopImpersonating().subscribe(() => this.preImpersonatingChecks(user)
        )
      } else {
        this.preImpersonatingChecks(user);
      }
    });
  }

  /**
   * Function to hit the stop impersonating endpoint
  */
  public stopImpersonating(): void {
    this.userService.stopImpersonating().subscribe(() => {
      this.landingPageRedirect();
    });
  }
    
  /**
   * This will check to see if the input is an LUID. If it is,
   * then it will hit the endpoint to get the corresponding
   * username. It will then call the function that actually impersonates.
   * @param userOrId
  */
  private preImpersonatingChecks(userOrId: string): void {
    let err = false;
    /* if only digits, convert  */
    if (this.integersRegex.test(userOrId)) {
      try {
        userOrId = this.padLuid(userOrId);
      } catch (e) {
        err = true;
        this.displayErrorToast(userOrId);
      }
    }

    if (err) return;

    /**
     * If input matches a LUID, then hit the endpoint to get the username.
     * If it throws an error on that call OR if it doesn't match,
     * then call the impersonate endpoint as normal.
    */
    
    if (this.luidRegex.test(userOrId)) {
      this.userService.getUsernameFromLuid(userOrId).pipe(
        catchError(() => of({ value: userOrId })),
      ).subscribe({
        next: (username) => this.startImpersonating(username.value),
        error: () => this.displayErrorToast(userOrId)
      });
    } else {
      this.startImpersonating(userOrId);
    }
  }

  /**
   * This function will hit the impersonate endpoint to begin
   * impersonating. It will display an error toast on an error
   * @param username
  */
  private startImpersonating(username: string): void {
    this.userService.impersonateUser(username).subscribe({
        next: () => {
          this.landingPageRedirect();
        },
        error: () => this.displayErrorToast(username)
    });
  }

  private landingPageRedirect(): void {
    this.router.navigateByUrl('')
      .then(() => this.reloadPage())
      .catch(() => this.reloadPage());
  }

  /**
   * This will display a standard error message if the username
   * or LUID is invalid.
  */
  private displayErrorToast(invalidUsername: string): void {
    let message = "Make sure you're using a valid username or LU ID and try again. ";

    if (invalidUsername) {
      invalidUsername = invalidUsername.trim();
      message += "<br>Username/ID used: <strong>" + invalidUsername + "</strong>";
    }

    this.messageDialogComponent.toastLike = true;
    this.messageDialogComponent.open({title: "Unable to impersonate", message: message});
  }

  /**
   * This will pad a string of digits with a "L" and preceeding 0's
   * until it matches the standard LUID pattern. It will throw an
   * error if the length of the passed in string is greater than 8.
   * @throws error
   * @param digits
   * @returns string
  */
  private padLuid(digits: string): string {
    let chars = digits.length;

    if (chars > 8) throw new Error('Too many digits. The input is definitely not an username or LUID.');

    let response = digits;

    for (chars; chars < 8; chars++) {
      response = '0' + response;
    }
    return 'L' + response;
  }

  /**
   * This method reloads the window.
   * This is used for testing purposes
   * @returns void
  */
  private reloadPage(): void {
    window.location.reload();
  }

}