import { Component, OnInit } from '@angular/core';
import { ERole } from './core/enums/roles.enum';
import { RoleService } from './core/services/roles.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  public isAdmin:boolean;

  constructor(private roleService: RoleService){}

  ngOnInit(): void {
    this.isAdmin = this.roleService.hasAnyRole([ERole.SHEPHERDS_ADMIN, ERole.STAFF_RSCGL, ERole.STAFF_AS, ERole.STAFF_CS])
  }
}