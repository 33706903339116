export enum EApplicationStatus {
    AWAITING_RS_RECOMMENDATION = 100,
    PENDING = 101,
    NOT_RECOMMENDED_BY_RS = 102,
    PENDING_INTERVIEW = 103,
    PENDING_FINAL_DECISION = 104,
    APPROVED = 105,
    DENIED_BY_LU_SHEPHERD = 106,
    APPROVED_OTHER_POSITION = 107,
    WITHDRAWN_BY_APPLICANT = 108,
    EXPIRED = 109
}

export function StatusCodeToString(status: EApplicationStatus): string {
    return ({
        [EApplicationStatus.AWAITING_RS_RECOMMENDATION]: "Awaiting RS Recommendation",
        [EApplicationStatus.PENDING]: "Awaiting Shepherds Review",
        [EApplicationStatus.NOT_RECOMMENDED_BY_RS]: "Not Recommended by RS",
        [EApplicationStatus.PENDING_INTERVIEW]: "Interview Requested",
        [EApplicationStatus.PENDING_FINAL_DECISION]: "Awaiting Shepherds Approval",
        [EApplicationStatus.APPROVED]: "Approved",
        [EApplicationStatus.DENIED_BY_LU_SHEPHERD]: "Denied by LU Shepherd",
        [EApplicationStatus.APPROVED_OTHER_POSITION]: "Approved Other Position",
        [EApplicationStatus.WITHDRAWN_BY_APPLICANT]: "Withdrawn by Applicant",
        [EApplicationStatus.EXPIRED]: "Application Expired",
    })[status];
}
