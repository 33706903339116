<div class="overlay" *ngIf="isOpen" [ngClass]="{'toast-like' : toastLike}">
    <div class="overlayClickLayer" *ngIf="!toastLike" (click)="close()"></div>
    <div class="modalContainer" [ngClass]="{'toast-like' : toastLike}">
        <div class="modal" [ngClass]="{'toast-like' : toastLike}">
            <div class="modal-header">
                <h2 class="modal-title" *ngIf="title">{{title}}</h2>
                <div></div>
                <button class="modal-close" (click)="close()">
                    <svg class="close-icon" width="100%" height="100%" viewBox="0 0 18 18">
                        <path stroke-linecap="round" stroke="black" class="path two" d="M 2 2 L 16 16 M 16 2 L 2 16" />
                      </svg>
                </button>
            </div>
            
            <p>{{message}}</p>
            <div class="footer">
                <button (click)="close()">Ok</button>
            </div>
        </div>
    </div>
</div>