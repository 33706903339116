import { Observable, Subject } from 'rxjs';

/* eslint-disable @typescript-eslint/no-explicit-any */

export class DialogRef {

  close(result?: any) {
    this._afterClosed.next(result);
  }

  private readonly _afterClosed = new Subject<any>();
  afterClosed: Observable<any> = this._afterClosed.asObservable();
}