import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoadingService {

  // When subscribed, true indicates there are currenlty pending requests
  public pending$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  // Array to store the current pending requests
  public pendingRequests: Array<string> = [];

  public incrementPending(url: string): void {
    this.pending$.next(true);
    this.pendingRequests.push(url);
  }

  public decrementPending(url: string): void {
    this.pendingRequests.splice(this.pendingRequests.findIndex(urlInList => urlInList === url), 1);
    if (this.pendingRequests.length == 0) {
      this.pending$.next(false);
    }
  }
}
