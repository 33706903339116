import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IAcademicYear } from 'src/app/shared/models/academic-year.model';
import { IApplicationWindow } from 'src/app/shared/models/application-window.model';
import { Cacheable } from 'ts-cacheable';
import { ApplicationWindowRepository } from '../repositories/application-window.repository';
import { PopupHttpErrors } from "../decorators/http-error/popup-http-errors.decorator";

@Injectable({
    providedIn: 'root'
})
export class ApplicationWindowService {

    constructor(private applicationWindowRepo: ApplicationWindowRepository) { }

    @Cacheable()
    @PopupHttpErrors()
    public getApplicationWindows(): Observable<Array<IApplicationWindow>> {
        return this.applicationWindowRepo.getApplicationWindows();
    }

    @Cacheable()
    @PopupHttpErrors()
    public getActiveApplicationWindows(applicationType?: string): Observable<Array<IApplicationWindow>> {
        return this.applicationWindowRepo.getActiveApplicationWindows(applicationType);
    }

    @Cacheable()
    @PopupHttpErrors()
    public getApplicationWindow(applicationType: string, acyrCode: string): Observable<IApplicationWindow> {
        return this.applicationWindowRepo.getApplicationWindow(applicationType, acyrCode);
    }

    @PopupHttpErrors()
    public getApplicationWindowByAcyrCode(acyrCode: string): Observable<Array<IApplicationWindow>> {
      return this.applicationWindowRepo.getApplicationWindowByAcyrCode(acyrCode);
    }

    @PopupHttpErrors()
    public getNextAcademicYearOfApplicationWindows(): Observable<IAcademicYear> {
      return this.applicationWindowRepo.getNextAcademicYearOfApplicationWindows();
    }
  
    @PopupHttpErrors()
    public getApplicationWindowByTypeAndAcyrCode(type: string, acyrCode: string): Observable<IApplicationWindow> {
      return this.applicationWindowRepo.getApplicationWindowByTypeAndAcyrCode(type, acyrCode);
    }

    @PopupHttpErrors()
    public updateApplicationWindows(acyrCode: string, applicationWindows: Array<IApplicationWindow>): Observable<Array<IApplicationWindow>> {
      return this.applicationWindowRepo.updateApplicationWindows(acyrCode, applicationWindows);
    }

    @PopupHttpErrors()
    public createApplicationWindows(acyrCode: string, applicationWindows: Array<IApplicationWindow>): Observable<Array<IApplicationWindow>> {
      return this.applicationWindowRepo.createApplicationWindows(acyrCode, applicationWindows);
    }
}