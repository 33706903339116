import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InjectorContainerModule } from './core/injector-container/injector-container.module';
import { AuthInterceptor } from './core/interceptors/auth/auth.interceptor';
import { LoadingInterceptor } from './core/services/interceptors/loading/loading-interceptor.service';
import { RoleService } from './core/services/roles.service';
import { DialogModule } from './dialog/dialog.module';
import { AdminHeaderComponent } from './features/admin-dashboard/admin-header/admin-header.component';
import { StudentLandingPageComponent } from './features/landing-page/student-landing-page.component';
import { DialogConfig } from './shared/components/dialog/dialog-config';
import { DialogRef } from './shared/components/dialog/dialog-ref';
import { SharedModule } from './shared/shared.module';



function initializeApp(roleService: RoleService) {
  return () => roleService.loadRoles();
}
@NgModule({ declarations: [
        AppComponent,
        StudentLandingPageComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        InjectorContainerModule,
        SharedModule,
        AdminHeaderComponent,
        DialogModule], providers: [
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [RoleService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: DialogConfig, useClass: DialogConfig },
        { provide: DialogRef, useClass: DialogRef },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
