<div class="dropdown" clickOrKeyupOutside (clickOutsideEmitter)="clickedOutside()" (KeyupOutsideEmitter)="tabbedOutside()">
    <button class="dropbtn" (click)="toggleDropdown()" id="menu-button" aria-haspopup="true" attr.aria-expanded="{{showMenu}}">{{ username }}<app-lu-icon icon="chevron-down" class="menu-icon"></app-lu-icon></button>
    <ul [class.show-dropdown]="showMenu" class="dropdown-content" id="drop-down-menu" aria-labelledby="menu-button">
        <li *ngFor="let option of menuOptions">
            <button *ngIf="option.action && option.isVisible && option.isVisible()" (click)="option.action()">
                <app-lu-icon icon={{option.icon}}></app-lu-icon>
                {{option.optionText}}
            </button>
            <a tabindex="0" href="{{option.path}}" *ngIf="option.external === true">{{option.optionText}}</a>
            <a tabindex="0" href="#" *ngIf="option.external === undefined && option.path" [routerLink]="option.path">{{option.optionText}}</a>
        </li>
    </ul>
</div>