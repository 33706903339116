import { Component, Input, OnChanges } from '@angular/core';
import { doesStatusMatch } from 'src/app/core/functions/does-status-match.function';
import { EApplicationStatus, StatusCodeToString } from '../../enums/application-status.enum';
import { IApplication } from '../../models/application.model';

@Component({
  selector: 'app-status-display',
  templateUrl: './status-display.component.html',
  styleUrls: ['./status-display.component.scss']
})
export class StatusDisplayComponent implements OnChanges {

  @Input() application: IApplication;

  @Input() public badgeOnly: boolean

  @Input() public showCheck: boolean;

  @Input() public showX: boolean;

  public statusText: string;

  ngOnChanges(): void {
    const statusCode = this.application?.status.statusCode;

    this.statusText = statusCode === EApplicationStatus.APPROVED_OTHER_POSITION ? 'Approved as ' + this.application?.approvalType : StatusCodeToString(statusCode);

    if (!this.badgeOnly) {
      this.showCheck = doesStatusMatch(statusCode, [EApplicationStatus.AWAITING_RS_RECOMMENDATION, EApplicationStatus.APPROVED, EApplicationStatus.APPROVED_OTHER_POSITION]);
      this.showX = doesStatusMatch(statusCode, [EApplicationStatus.NOT_RECOMMENDED_BY_RS, EApplicationStatus.DENIED_BY_LU_SHEPHERD, EApplicationStatus.WITHDRAWN_BY_APPLICANT, EApplicationStatus.EXPIRED]);
    }
  }
}
