<form (ngSubmit)="impersonate()" class="form-body">
  <div class="modal-body">
    <app-close-icon (click)="dialog.close()"></app-close-icon>

    <h1>Impersonate a User</h1>

    <div class="form-group">
      <label for="impersonate-username">Enter a username or LU ID</label>
      <small>Required</small>
      <input #usernameIdInput type="text" class="form-control lu-form-control"
        placeholder="user to impersonate"
        [formControl]="impersonateInput"
        autofocus>
    </div>
    <p class="impersonate-history-title">Previously Used:</p>
    <ul class="impersonate-history">
      <li *ngFor="let record of history">
        <a (click)="impersonate(record)">{{record}}</a>
      </li>
    </ul>
    <div *ngIf="impersonateInput.invalid && impersonateInput.dirty"
      class="error">
      <div *ngIf="impersonateInput.errors?.['customRequired']; else other">
        *This field is required
      </div>
      <ng-template #other>
        *You must enter a valid username <i>or</i> Liberty ID
      </ng-template>
    </div>

  </div>
  <footer class="container">
    <div class="row">
      <div class="col-6 m-auto text-center">
        <a href="javascript:void(0);" class="btn-link" (click)="dialog.close()">Cancel</a>
      </div>

      <button type="submit" class="col-6 btn btn-primary" [disabled]="impersonateInput.invalid">Impersonate User</button>
    </div>
  </footer>
</form>