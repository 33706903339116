import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      withCredentials: true,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return next.handle(request)
      .pipe(catchError(response => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (response.status == 401) {
          this.redirect(environment.unauthenticatedUrl());
          return of(response);
        } else {
          return throwError(response);
        }
      }));
  }

  public redirect(toUrl: string): void {
    return window.location.replace(toUrl);
  }
}
