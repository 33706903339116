import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { DialogConfig } from 'src/app/shared/components/dialog/dialog-config';
import { DialogRef } from '../dialog/dialog-ref';

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Remove all white spaces and convert to Upper Case
 * @param str
 */
export function prepareString(str: string): string {
  return str.replace(/\s/g, '').toUpperCase();
}

/**
 * Validation function to check if input is not null
 * @returns ValidatorFn
 */
export function customRequired(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    /* check if sanitized value is not null */
    const len = prepareString(control.value).length;
    return len <= 0 ? { 'customRequired': { value: control.value } } : null;
  };
}

/**
 * Validation function to check if input is all digits. If yes, it checks
 * if the length is not greater than 8
 * @returns ValidatorFn
 */
export function digitCheck(): ValidatorFn {
  const integersRegex = new RegExp(/^\d+$/);
  return (control: AbstractControl): { [key: string]: any } | null => {
    /* check if input is all digits and is less than 8 */
    const val = prepareString(control.value);
    return (integersRegex.test(val) && val.length > 8) ? { 'digitCheck': { value: val } } : null;
  };
}

/**
 * Validation function to check if input matches a 'bad' LUID.
 * L+(1 to 7) OR L+(9 or more) digits
 * @returns ValidatorFn
 */
export function luidCheck(): ValidatorFn {
  const badLuidRegex = new RegExp(/^L\d{1,7}$|^L\d{9,}$/i);
  return (control: AbstractControl): { [key: string]: any } | null => {
    /* check if input is a bad luid */
    const val = prepareString(control.value);
    return badLuidRegex.test(val) ? { 'luidCheck': { value: control.value } } : null;
  };
}

@Component({
  selector: 'app-impersonate-modal',
  templateUrl: './impersonate-modal.component.html',
  styleUrls: ['./modal.scss',
    './impersonate-modal.component.scss'
  ],
})

export class ImpersonateModalComponent implements OnInit, AfterViewInit {
  @ViewChild('usernameIdInput')
  usernameIdInput: any;

  impersonateInput = new UntypedFormControl('', [
    customRequired(),
    digitCheck(),
    luidCheck()
  ]);

  constructor(public config: DialogConfig, public dialog: DialogRef) { }

  public history: Array<string> = [];

  ngOnInit(): void {
    this.getHistory();
  }

  public ngAfterViewInit(): void {
    this.usernameIdInput.nativeElement.focus();
  }

  public impersonate(user?: string): void {
    const storedHistory = localStorage.getItem('impersonate-history');
    const result = user ? user : this.impersonateInput.value;

    if (storedHistory) {
      this.history = JSON.parse(storedHistory);
      const resultIndex = this.history.indexOf(result);
      if (resultIndex > -1) {
        this.history.splice(resultIndex, 1)
      }

      if (this.history.length >= 10) {
        this.history.push(result);
        this.history.shift();
        localStorage.setItem('impersonate-history', JSON.stringify(this.history))
      } else {
        this.history.push(result);
        localStorage.setItem('impersonate-history', JSON.stringify(this.history))
      }
    } else {
      this.history.push(result);
      localStorage.setItem('impersonate-history', JSON.stringify(this.history))
    }

    if (user) {
      this.dialog.close(prepareString(user));
    } else {
      this.dialog.close(prepareString(this.impersonateInput.value));
    }

  }

  public getHistory(): void {
    const storedHistory = localStorage.getItem('impersonate-history');
    if (storedHistory) {
      this.history = JSON.parse(storedHistory)
      this.history = this.history.reverse();
    }
  }

}