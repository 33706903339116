import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHello } from 'src/app/shared/models/hello.model';
import { IBasePerson } from 'src/app/shared/models/person.model';
import { PopupHttpErrors } from '../decorators/http-error/popup-http-errors.decorator';
import { ERole } from '../enums/roles.enum';
import { UserRepository } from '../repositories/user.repository';

@Injectable({ providedIn: 'root' })
export class UserService {

  private seenImpersonateToast = false;

  constructor(
    private userRepository: UserRepository
  ) { }

    /**
   * Identifies the current user
   *
   * @returns {Observable<string>} the username being impersonated, or the actually logged in user
   * @memberof UserRepository
   */
    public whoAmI(): Observable<string> {
      return this.userRepository.whoAmI();
    }

  /**
   * Say hello to the current user
   */
  @PopupHttpErrors({popupMessageConfigs:[{ statusCode: 500, message: 'The user service is currently not available. Please try again later.' }]})
  public helloUser(): Observable<IHello> {
    return this.userRepository.getPerson();
  }

  /**
   * Returns true If the Impersonate toast should be seen.
   */
  public shouldSeeImpersonateToast(): boolean {
    let seeImpersonate = false;

    // If the Impersonate Toast has not been seen yet.
    if (!this.seenImpersonateToast) {
      this.seenImpersonateToast = true;
      seeImpersonate = true;
    }
    return seeImpersonate;
  }

  /**
   * Impersonate's username passed in
   *  Api will issue a redirect if successful
   * @param username
   */
  @PopupHttpErrors({popupMessageConfigs:[{ statusCode: 500, message: 'The impersonate service is currently unavailable.' }]})
  public impersonateUser(username: string): Observable<IHello> {
    return this.userRepository.impersonateUser(username);
  }

  @PopupHttpErrors()
  public getUsernameFromLuid(luid: string): Observable<{ value: string }> {
    return this.userRepository.getUsernameFromLuid(luid);
  }

  /**
   * Exit impersonate session
   */
  @PopupHttpErrors()
  public stopImpersonating(): Observable<IBasePerson> {
    return this.userRepository.stopImpersonating();
  }

  /**
   * Log out of the application
   */
  public logOut(): void {
    window.location.replace(this.userRepository.getLogoutUrl());
  }

  /**
   * returns true if the user can impersonate any student (and not currently impersonating)
   *
   * Note that this doesn't just call hasRole(roleSwitchUser) because we want to know if the user is capable of
   * impersonating right now, and exclude the possibility that they have the role and are currently impersonating.
   */
  public canImpersonate(): Observable<boolean> {
    return this.getUserRoles().pipe(
      map((roles: Array<string>) => roles.includes(ERole.SHEPHERDS_SWITCH_USER))
    );
  }

  /**
   * returns true if the user is currently impersonating
   */
  public isImpersonating(): Observable<boolean> {
    return this.helloUser().pipe(
      map((hello: IHello) => !!hello.impersonatingUser)
    );
  }

  /**
   * returns true if the user has or had an admin role, whether they are impersonating or not
   */
  public isAdmin(): Observable<boolean> {
    return this.hasRole(ERole.SHEPHERDS_SWITCH_USER);
  }

  /**
   * Get user AD roles
   */
  @PopupHttpErrors({popupMessageConfigs:[{ statusCode: 500, message: 'The roles service is currently unavailable' }]})
  public getUserRoles(): Observable<Array<string>> {
    return this.userRepository.getUserRoles();
  }

  /**
   * returns true if the user's roles include the given role
   * works regardless of if the user is impersonating.
   *
   * Use explicitly-named role methods for everything you can (i.e. isAdmin()). This parameterized one should only be
   * used if you won't know until runtime which role you need to check for.
   *
   * @private
   * @param {string} role
   * @returns {Observable<boolean>}
   * @memberof UserService
   */
  public hasRole(role: string): Observable<boolean> {
    return this.getUserRoles().pipe(
      map(roles => {
        return roles.some((r: string | string[]) => {
          return r.includes(role);
        });
      })
    );
  }
}