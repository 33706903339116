export enum ERole {
    SHEPHERDS_ADMIN = 'ROLE_SHEPHERDS_ADMIN',
    SHEPHERDS_SWITCH_USER = 'ROLE_SHEPHERDS_SWITCH_USER',

    SHEPHERDS_CONFIG = 'ROLE_SHEPHERDS_CONFIG',

    CURRENT_RS = "ROLE_SHEPHERDS_CURRENT_RS",
    FUTURE_RS = "ROLE_SHEPHERDS_FUTURE_RS",

    STAFF_RSCGL = "ROLE_SHEPHERDS_STAFF_RSCGL",
    STAFF_AS = "ROLE_SHEPHERDS_STAFF_AS",
    STAFF_CS = "ROLE_SHEPHERDS_STAFF_CS"
}
