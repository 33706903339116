import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IAcademicYear } from 'src/app/shared/models/academic-year.model';
import { IApplicationWindow } from "src/app/shared/models/application-window.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class ApplicationWindowRepository {
  
    private readonly applicationWindowUrl: string = `${environment.apiUrl}/application-windows`;
  
    constructor(private http: HttpClient) { }

    public getApplicationWindows(): Observable<Array<IApplicationWindow>> {
        return this.http.get<Array<IApplicationWindow>>(this.applicationWindowUrl);
    }

    public getActiveApplicationWindows(applicationType?: string): Observable<Array<IApplicationWindow>> {
        
        if(applicationType) {
            return this.http.get<Array<IApplicationWindow>>(this.applicationWindowUrl, {params: {'active': true, 'applicationType': applicationType}});
        }
        
        return this.http.get<Array<IApplicationWindow>>(this.applicationWindowUrl, {params: {'active': true}});
    }

    public getApplicationWindow(applicationType: string, acyrCode: string): Observable<IApplicationWindow> {
        return this.http.get<IApplicationWindow>(this.applicationWindowUrl, {params: {'applicationType': applicationType, 'acyrCode': acyrCode}});
    }

    public getApplicationWindowByAcyrCode(acyrCode: string): Observable<Array<IApplicationWindow>> {
        return this.http.get<Array<IApplicationWindow>>(this.applicationWindowUrl+`/windows-by-acyr-code/${acyrCode}`, {});
    }

    public getApplicationWindowByTypeAndAcyrCode(type: string, acyrCode: string): Observable<IApplicationWindow> {
        return this.http.get<IApplicationWindow>(this.applicationWindowUrl+`/window-by-type-and-acyr-code/${type}/${acyrCode}`, {});
    }

    public getNextAcademicYearOfApplicationWindows(): Observable<IAcademicYear> {
        return this.http.get<IAcademicYear>(this.applicationWindowUrl+`/next-academic-year`, {});
    }

    public updateApplicationWindows(acyrCode: string, applicationWindows: Array<IApplicationWindow>): Observable<Array<IApplicationWindow>> {
        return this.http.put<Array<IApplicationWindow>>(`${this.applicationWindowUrl}/${acyrCode}/save-application-windows`, applicationWindows);
    }

    public createApplicationWindows(acyrCode: string, applicationWindows: Array<IApplicationWindow>): Observable<Array<IApplicationWindow>> {
        return this.http.post<Array<IApplicationWindow>>(`${this.applicationWindowUrl}/${acyrCode}/create-application-windows`, applicationWindows);
    }

  }