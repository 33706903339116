<div class="content-left">
    <div id="menu">
        <input type="checkbox" [(ngModel)]="menuOpen" (click)="showMenu()" role="button" [attr.aria-label]="!menuOpen ? 'Open Menu' : 'Close Menu'"/>
        <div class="menu-bar menu-bar--top">
          <div class="menu-bar--inner"></div>
        </div>
        <div class="menu-bar menu-bar--middle">
          <div class="menu-bar--inner"></div>
        </div>
        <div class="menu-bar menu-bar--bottom">
          <div class="menu-bar--inner"></div>
        </div>
      </div>
    <div class="app-logo">
        <!-- ICON -->
        <app-lu-icon icon="monogram" class="logo-icon"></app-lu-icon>
        <span class="logo-text">Shepherds</span>
    </div>
    <nav id="main-nav" [ngClass]="{'showNav' : menuOpen}" [attr.aria-hidden]="!menuOpen">
        <ul>
            <li>
                <a tabindex="0" routerLink="">
                    <app-lu-icon icon="home"></app-lu-icon>
                    Home
                </a>
            </li>

            <li *ngIf="isDeveloper">
                <a tabindex="0" routerLink="/styleguide">
                    <app-lu-icon icon="layout"></app-lu-icon>
                    Styleguide
                </a>
            </li>

            <li>
                <a tabindex="0" routerLink="/profile">
                    <app-lu-icon icon="person"></app-lu-icon>
                    Profile
                </a>
            </li>

            <li *ngIf="menuOpen" class="dropdown">
                <details>
                    <summary>{{user?.currentUser?.printName}}</summary>
                    <ul>
                        <li *ngFor="let option of menuOptions">
                            <a href="{{option.path}}" *ngIf="option.external === true">{{option.optionText}}</a>
                            <a href="#" *ngIf="option.external === undefined" [routerLink]="option.path">{{option.optionText}}</a>
                        </li>
                    </ul>
                </details>
            </li>
           
        </ul>
        
    </nav>
</div>
<div class="content-right">
    <app-menu-dropdown [username]="currentUser?.printName" [menuOptions]="menuOptions"></app-menu-dropdown>
</div>
