import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHello, IHelloOld } from 'src/app/shared/models/hello.model';
import { IBasePerson } from 'src/app/shared/models/person.model';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class UserRepository {
  public readonly impersonateUrl: string = `${environment.apiUrl}/switch-user?username=`;
  public readonly unauthenticatedUrl: string = environment.unauthenticatedUrl();

  constructor(
    private http: HttpClient,
  ) { }

  public whoAmI(): Observable<string> {
    return this.http.get<{ value: string }>(`${environment.apiUrl}/who-am-i`).pipe(
      map((wrapper: { value: string }) => wrapper.value)
    );
  }

  public getUsernameFromLuid(luid: string): Observable<{ value: string }> {
    return this.http.get<{ value: string }>(`${environment.apiUrl}/username?luid=${luid}`);
  }

  public getPerson(): Observable<IHello> {
    return forkJoin([
      this.http.get<IHelloOld>(`${environment.apiUrl}/hello-user`),
      this.http.get<Array<string>>(`${environment.apiUrl}/roles.json`)],
    ).pipe(
      map(([hello, roles]: [IHelloOld, Array<string>]) => { 
        return Object.assign({}, hello, { roles })}),
    );
  }

  public getUserRoles(): Observable<Array<string>> {
    return this.getPerson().pipe(
      map((hello: IHello) => hello.roles)
    );
  }

  //because of a Spring Security update, this is now a POST instead of a GET
  public impersonateUser(username: string): Observable<IHello> {
    return this.http.post<IHello>(`${this.impersonateUrl}${username}`, null);
  }

  //because of a Spring Security update, this is now a POST instead of a GET
  public stopImpersonating(): Observable<IBasePerson> {
    return this.http.post<IBasePerson>(`${environment.apiUrl}/exit-user`, null);
  }

  public getLogoutUrl(): string {
    return `${environment.apiUrl}/logout`;
  }

  private getLoggedInUser(hello: IHello): string {
    return (hello.impersonatingUser || hello.currentUser).username;
  }
}