import { Injectable } from "@angular/core";
import { Observable, tap, switchMap, EMPTY } from "rxjs";
import { ERole } from "../enums/roles.enum";
import { RoleRepository } from "../repositories/roles.repository";

@Injectable({
    providedIn: 'root'
})
export class RoleService {

    private grantedRoles: Array<ERole> = [];

    constructor(private roleRepository: RoleRepository) { }

    public loadRoles(): Observable<void> {
        return this.roleRepository.getRoles().pipe(
            tap(roles => this.grantedRoles = roles),
            switchMap(() => EMPTY)
        );
    }

      /**
   * Checks if the current user has the specified role. Use convenience class Roles for valid roles to pass in.
   * i.e. roleService.hasRole(ERole.EDIT_COMMENTS)
   * @param role
   * @returns true if the current user's role(s) list contains the specified role
   * @see {@link ERole}
   */
  public hasRole(role: ERole): boolean {
    return this.grantedRoles.includes(role);
  }

    /**
   * Checks if the current user has at least one of the given roles. Use convenience class RoleGroup for commonly used
   * collections of ERole.
   * i.e. roleService.hasAnyRole(RoleGroup.STUDENT_ROLE_GROUP)
   * or roleService.hasAnyRole([ERole.EDIT_COMMENTS, ERole.IR_SUBMIT])
   * @param roles
   * @returns true if the current user's role(s) list contains at least 1 of the specified roles
   * @see {@link ERole}
   * @see {@link RoleGroup}
   */
    public hasAnyRole(roles: Array<ERole>): boolean {
      if (roles.length == 0) {
        return false;
      }
        return roles.some(role => this.grantedRoles.includes(role));
      }
}