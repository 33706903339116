import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {

  @Output() dismissNotification: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() followRoute: EventEmitter<boolean> = new EventEmitter<boolean>();

  dismiss(): void {
    this.dismissNotification.emit(true);
  }

  follow(): void {
    this.followRoute.emit(true);
  }   
}
