import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SlModalComponent, SlModalService } from '@sl/sl-angular-library';
import { Observable, throwError } from 'rxjs';
import { PopupHttpErrorsConfig } from '../../decorators/http-error/models/popup-http-errors.config';
import { PopupMessageConfig } from '../../decorators/http-error/models/popup-message-config';
import { RestErrorResponse } from '../../decorators/http-error/models/rest-error-response';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {
  private readonly DEFAULT_TITLE = 'Error';
  private readonly DEFAULT_CONFIGS: PopupMessageConfig[] = [
    {
      statusCode: 0,
      message: `We're sorry, we weren't able to retrieve your data. Please try refreshing the page and, if the problem
          persists, contact the IT Helpdesk so we can resolve the issue.`
    },
    {
      statusCode: -1,
      message: `We're sorry, we weren't able to retrieve your data. Please try refreshing the page and, if the problem
          persists, contact the IT Helpdesk so we can resolve the issue.`
    },
    {
      statusCode: 403,
      message: 'You are not permitted to access this resource.'
    },
    {
      statusCode: 404,
      message: `The server is not responding to requests. Please try again and, if the problem persists, contact the IT
          HelpDesk so we can resolve the issue.`
    },
    {
      statusCode: 500,
      message: `We're sorry, something went wrong. Please contact the IT HelpDesk so we can resolve the issue.`
    },
    {
      statusCode: 502,
      message: 'The request has timed out. Please try again or contact IT Help Desk if you are unable to proceed.'
    },
    {
      statusCode: 504,
      message: 'The request has timed out. Please try again or contact IT Help Desk if you are unable to proceed.'
    },
    {
      statusCode: 503,
      message: `We're sorry, something unexpected happened. Please try again and, if the problem persists, contact the
          IT HelpDesk so we can resolve the issue.`
    },
  ];

  private recentLogs: string[] = [];

  public errorModal: SlModalComponent;

  constructor(
    private slModalService: SlModalService,
  ) { }

  /**
   * Resolves popup behavior for HttpErrorResponses.
   *
   * Whether or not a popup is shown is determined by a number of factors. If an invalid status (null or undefined) was
   * provided, or the status is a 401 (Unauthorized), or if the status is included in the suppressPopupsForCode
   * property of the provided PopupHttpErrorsConfig, then a popup will NOT be shown and handling will be passed to the
   * next element in the Observable chain. Additionally, if a popup with the exact same message was shown within the
   * last second, the popup that would've been shown for this error will be suppressed. Once a second is passed, the
   * same message will be eligible to show up in a popup again.
   *
   * The contents of the dialog are resolved in the following order:
   * 1. If a custom PopupMessageConfig was provided in the decorator declaration with a matching statusCode for the
   *  error, the message within will be be used, and the title if provided
   * 2. If no custom PopupMessageConfig was provided and there's a server-side message in the response payload in one
   *  of the accepted fields (prioritized by: errorMessage, message, array of validation errors with field and
   *  defaultMessage properties), then that is the message that will be used.
   * 3. If no custom PopupMessageConfig was provided and a default PopupMessageConfig was and has a title, then that
   *  title will be used.
   * 4. If no custom PopupMessageConfig was provided and there was no server-side message and a default PopupMessage
   *  was, then that message will be used.
   * 5. If none of the above means provide a title/message, then a generic default title/message will be used
   *
   * @param error the http error response that was caught
   * @param popupConfig (optional) specifications on whether a popup should display and how to display them
   * @see PopupHttpErrorsConfig
   * @see PopupMessageConfig
   */
  public popupError(errorResponse: HttpErrorResponse, popupConfig: PopupHttpErrorsConfig): Observable<any> {
    // Don't show popup if there is no status code, this is a 401, or the errorResponse is for a suppressed statusCode
    if (
      errorResponse.status === undefined ||
      errorResponse.status === null ||
      errorResponse.status === 401 ||
      popupConfig.suppressPopupsForCodes?.includes(errorResponse.status)) {
      // Rethrow the error so the caller can resolve
      return throwError(errorResponse);
    }

    // Resolve config popup config matches
    const matchingCustomPopupMessageConfig = popupConfig.popupMessageConfigs?.find(customConfig =>
      customConfig.statusCode === errorResponse.status);
    const matchingDefaultPopupMessageConfig = this.DEFAULT_CONFIGS
      .find(defaultConfig => defaultConfig.statusCode === errorResponse.status);

    // Resolve dialog content
    const errorModalTitle = this.resolvePopupTitle(matchingCustomPopupMessageConfig, matchingDefaultPopupMessageConfig);
    const errorModalMessage = this.resolvePopupMessage(
      errorResponse, matchingCustomPopupMessageConfig, matchingDefaultPopupMessageConfig);

    this.resolveErrorPopup(errorModalTitle, errorModalMessage);

    // Rethrow the error so the observable chain can resolve
    return throwError(errorResponse);
  }

  /**
   * Prioritizes the customConfig's title if it exists, then the defaultConfig's, and defaults to DEFAULT_TITLE when
   * neither is available
   * @param customConfig (optional) the custom config that matches the error we're resolving a title for
   * @param defaultConfig (optional) the default config that matches the error we're resolving a title for
   */
  private resolvePopupTitle(customConfig?: PopupMessageConfig, defaultConfig?: PopupMessageConfig): string {
    if (customConfig?.title) return customConfig.title;
    if (defaultConfig?.title) return defaultConfig.title;
    return this.DEFAULT_TITLE;
  }

  /**
   * Resolves the error message that should be shown in the popup. Prioritizes the customConfig's message if available,
   * then attempts to retrieve a server-side message of varying formats, and only if that fails will it use a message
   * from the defaultConfig, if available. If none of the above attempts to get a message are successful, a very generic
   * message will be returned that includes the error status code.
   *
   * @param errorResponse the original error thrown & where server-side error messages are retrieved
   * @param customConfig (optional) the custom cuonfig that matches the error we're resolving a message for
   * @param defaultConfig (optional) the default config that matches the error we're resolving a message for
   */
  private resolvePopupMessage(errorResponse: HttpErrorResponse, customConfig?: PopupMessageConfig,
    defaultConfig?: PopupMessageConfig
  ): string {
    // Always use the custom popup config message when available
    return customConfig?.message
      // Then try to get a server-side message
      || this.resolveResponseBodyErrorMessage(errorResponse)
      // Fallback on defaults when no server-side message is found
      || defaultConfig?.message
      // When all else fails, fall back on default unknown message
      || 'An unexpected error occurred. Please try again and if the problem persists, send a screenshot of this error '
      + `to the helpdesk[Error Code: ${errorResponse.status}]`;
  }

  /**
   * If we got a message from the response payload in a format we recognize, display it, prioritizing errorMessage
   * over message over the first of an array of validation error mesages.
   */
  private resolveResponseBodyErrorMessage(errorResponse: RestErrorResponse): string | null {
    return errorResponse.error?.error|| null;
  }

  /**
   * Shows a popup with the given title and message if another popup with an identical message has not been shown
   * within the last second. Caches the shown popup for future checks on recent popups shown.
   * @param errorModalTitle the title to show in the popup
   * @param errorModalMessage the message to show in the popup
   */
  private resolveErrorPopup(errorModalTitle: string, errorModalMessage: string): void {
    // Only show the popup if an identical one has not been shown in the last second
    if (!this.recentLogs.includes(errorModalMessage)) {
      this.errorModal = this.slModalService.createModal({
        title: errorModalTitle,
        message: errorModalMessage,
        class: 'popup-error'
      });


      // Add this log to the recent logs to persist for one second
      this.recentLogs.push(errorModalMessage);
      setTimeout(() => {
        this.recentLogs.splice(this.recentLogs.indexOf(errorModalMessage), 1);
      }, 1000);
    }
  }
}