import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { adminRedirectGuard } from './core/guards/admin-redirect.guard';
import { studentRedirectGuard } from './core/guards/student-redirect.guard';
import { StudentLandingPageComponent } from './features/landing-page/student-landing-page.component';

const routes: Routes = [
  {
    path: '',
    component: StudentLandingPageComponent,
    canActivate: [adminRedirectGuard]
  },
  {
    path: 'dashboard/admin',
    loadChildren: () => import('./features/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule),
    canActivate: [studentRedirectGuard],
  },
  {
    path: 'dashboard/rs',
    loadChildren: () => import('./features/rs-dashboard/rs-dashboard.module').then(m => m.RsDashboardModule),
  },
  {
    path: 'dashboard/cgl-search',
    loadChildren: () => import('./features/future-rs-dashboard/future-rs-dashboard.module').then(m => m.FutureRsDashboardModule),
  },
  {
    path: 'styleguide',
    loadComponent: () => import('./shared/components/styleguide/styleguide.component').then(c => c.StyleguideComponent)
  },
  {
    path: 'apply/:applicationType/current',
    loadChildren: () => import('./features/application/application.module').then(m => m.ApplicationModule),
  },
  {
    path: 'apply/:applicationType',
    loadChildren: () => import('./features/application/application.module').then(m => m.ApplicationModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'thank-you/:applicationType',
    loadChildren: () => import('./features/thank-you/thank-you.module').then(m => m.ThankYouModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
