import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAcademicInformation } from 'src/app/shared/models/academic-information.model';
import { IHousingInformation } from 'src/app/shared/models/housing-information.model';
import { IBasePerson } from 'src/app/shared/models/person.model';
import { IViewableAcademicYears } from 'src/app/shared/models/viewable-academic-years.model';
import { Cacheable } from 'ts-cacheable';
import { PopupHttpErrors } from '../decorators/http-error/popup-http-errors.decorator';
import { PeopleRepository } from '../repositories/people.repository';

@Injectable({
    providedIn: 'root'
})
export class PeopleService {

    constructor(private peopleRepo: PeopleRepository) { }

    @PopupHttpErrors()
    public getCurrentUser(): Observable<IBasePerson> {
        return this.peopleRepo.whoAmI();
    }

    @PopupHttpErrors({popupMessageConfigs:[{statusCode:404,title:'Error 404',message:'Unable to get application windows and current users applications.'}]})
    public getViewableYears(): Observable<IViewableAcademicYears> {
        return this.peopleRepo.getViewableYears();
    }

    @PopupHttpErrors()
    public search(criteria: string): Observable<Array<IBasePerson>> {
      return this.performCachedSearch(criteria);
    }

    @PopupHttpErrors({suppressPopupsForCodes: [400]})
    public searchWithBadRequestErrorsReturned(criteria: string): Observable<Array<IBasePerson>> {
      return this.performCachedSearch(criteria);
    }
  
    // Caches results for the most recent 20 unique searches
    @Cacheable({ maxCacheCount: 20 })
    private performCachedSearch(criteria: string): Observable<Array<IBasePerson>> {
      return this.peopleRepo.search(criteria);
    }

    @PopupHttpErrors({suppressPopupsForCodes: [404]})
    public getHousingInformation(applicationId: number) : Observable<IHousingInformation> {
        return this.peopleRepo.getHousingInformation(applicationId);
    }

    @PopupHttpErrors({suppressPopupsForCodes: [404]})
    public getAcademicInformation(applicationId: number) : Observable<IAcademicInformation> {
        return this.peopleRepo.getAcademicInformation(applicationId);
    }
  
}