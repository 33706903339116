import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ERole } from 'src/app/core/enums/roles.enum';
import { ImpersonateService } from 'src/app/core/services/impersonate.service';
import { RoleService } from 'src/app/core/services/roles.service';
import { UserService } from 'src/app/core/services/user.service';
import { MessageDialogComponent } from 'src/app/dialog/message-dialog/message-dialog.component';
import { environment } from 'src/environments/environment';
import { IHello } from '../../models/hello.model';
import { IMenuOption } from '../../models/menu-option.model';
import { IBasePerson } from '../../models/person.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public user: IHello;
  public currentUser: IBasePerson | undefined;
  public isDeveloper = false;
  public menuOpen = false;

  private isImpersonating = false;
  public username: string | undefined = '';
  
  @ViewChild(MessageDialogComponent)
  protected messageDialogComponent: MessageDialogComponent;	

  public menuOptions: Array<IMenuOption> = [{
    optionText: 'Logout',
    path: `${environment.apiUrl}/logout`,
    external: true
  }];

  constructor(
    private roleService: RoleService,
    private router: Router,
    private userService: UserService,
    private impersonateService: ImpersonateService,
  ) {}

  ngOnInit(): void {
    this.isDeveloper = this.roleService.hasAnyRole([ERole.SHEPHERDS_SWITCH_USER]);
    
    this.userService.helloUser().subscribe(user => {
      if (user.currentUser != null) {
        this.user = user; 
        this.currentUser = user.currentUser
        this.username = user.currentUser.printName;
        this.isImpersonating = user.impersonatingUser != null;
        this.updateMenu(); 
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if(this.menuOpen){
          this.menuOpen = !this.menuOpen;
        }
      }
    });
  }

  private updateMenu() {
    if (this.isDeveloper || this.isImpersonating) {
      this.menuOptions.unshift(
        {
          icon: 'user-secret',
          optionText: this.isImpersonating ? 'Switch User' : 'Impersonate',
          isVisible: () => true,
          action: this.openImpersonateModal.bind(this)
        },
        {
          icon: 'user-times',
          optionText: 'Stop Impersonating',
          isVisible: () => this.isImpersonating,
          action: this.stopImpersonating.bind(this)
        }
      );
    }
  }

  public openImpersonateModal(): void {
    this.impersonateService.openImpersonateModal();
  }

  public stopImpersonating(): void {
    this.impersonateService.stopImpersonating();
  }

  public logout() {
    window.location.replace(`${environment.apiUrl}/logout`);
  }

  public isVisible():boolean {
    return true;
  }

  public showMenu():void{
    this.menuOpen = !this.menuOpen;
  } 

}
