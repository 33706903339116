<div class="notification-container">
  
  <app-close-icon class="close-icon" (click)="dismiss()"></app-close-icon>
  <div class="notification-box" (click)="follow()">
    <div class="icon-container" >
      <app-lu-icon icon="alert-circle" class="icon-placement"></app-lu-icon>
    </div>
    <div class="notification-content"><ng-content></ng-content></div>
  </div>
  
</div>   