import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InjectorContainerModule } from '../../injector-container/injector-container.module';
import { HttpErrorService } from '../../services/http-error/http-error.service';
import { PopupHttpErrorsConfig } from './models/popup-http-errors.config';


/**
 * This decorator catches errors thrown by the decorated method and passes them along to the error service for handling.
 *
 * Caution: Misuse of this decorator can lead to a single error being handled multiple times if this decorator is used
 * in 2 or more places in the same call chain. I.e. This decorator is placed on a component method as well as the
 * resource method that component method calls. Likewise, if not all paths to the http call are covered (i.e. this
 * decorator is used on 2 out of 3 components that make the same resource method call) then nothing will happen to
 * handle the error in the places where this decorator was forgotten to be added.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
export function PopupHttpErrors(popupConfig: PopupHttpErrorsConfig = new PopupHttpErrorsConfig()): any {
  return (target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<(...parameters: Array<any>) => Observable<any>>) => {
    const originalMethod = descriptor.value;

    if (!originalMethod) {
      throw Error('PopupHttpErrors decorator can only be used on methods');
    }

    descriptor.value = function(...parameters: Array<any>): Observable<any> {
      /* eslint-disable  @typescript-eslint/no-unsafe-argument */
      return originalMethod.call(this, ...parameters).pipe(catchError((errorResponse: HttpErrorResponse) => {
        const errorService = InjectorContainerModule.injector.get(HttpErrorService);
        return errorService.popupError(errorResponse, popupConfig);
      }));
    };

    return descriptor;
  };
}