import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IConductInformation } from 'src/app/shared/models/conduct-information.model';
import { IUserProfile } from 'src/app/shared/models/user-profile.model';
import { ProfileRepository } from '../repositories/profile.repository';
import { PopupHttpErrors } from '../decorators/http-error/popup-http-errors.decorator';
@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(private profileRepo: ProfileRepository) { }

    @PopupHttpErrors()
    public getProfile(pidm:number): Observable<IUserProfile> {
        return this.profileRepo.getProfile(pidm);
    }

    @PopupHttpErrors()
    public getMyProfile(): Observable<IUserProfile> {
        return this.profileRepo.getMyProfile();
    }

    @PopupHttpErrors()
    public getConductInformation(pidm: number): Observable<IConductInformation> {
        return this.profileRepo.getConductInformation(pidm);
    }

    @PopupHttpErrors()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public putOnlineContactInfo(onlineContactInfo:string, pidm:any): Observable<IUserProfile> {
        return this.profileRepo.putOnlineContactInfo(onlineContactInfo,pidm)
    }
}