import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ERole } from '../enums/roles.enum';
import { RoleService } from '../services/roles.service';

export const studentRedirectGuard = () => {
  const roleService = inject(RoleService);
  const router = inject(Router);
  
  if (!roleService.hasAnyRole([ERole.STAFF_RSCGL, ERole.STAFF_AS, ERole.STAFF_CS, ERole.SHEPHERDS_ADMIN])) {
    return router.createUrlTree(['']);
  }
    
  return true;
};