import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ERole } from '../enums/roles.enum';
import { RoleService } from '../services/roles.service';

export const adminRedirectGuard = () => {
  const roleService = inject(RoleService);
  const router = inject(Router);
  
  if (roleService.hasAnyRole([ERole.SHEPHERDS_ADMIN, ERole.STAFF_RSCGL, ERole.STAFF_AS, ERole.STAFF_CS])) {
    return router.createUrlTree(['dashboard/admin']);
  }
    
  return true;
};

export const configGuard = () => {
  const roleService = inject(RoleService);
  
  if (roleService.hasAnyRole([ERole.SHEPHERDS_CONFIG])) {
    return true;
  }
    
  return false;
};