import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IAcademicInformation } from 'src/app/shared/models/academic-information.model';
import { IHousingInformation } from 'src/app/shared/models/housing-information.model';
import { IBasePerson } from "src/app/shared/models/person.model";
import { IViewableAcademicYears } from "src/app/shared/models/viewable-academic-years.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PeopleRepository {

    private readonly repoUrl: string = `${environment.apiUrl}/people`;

    constructor(private http: HttpClient) { }

    public whoAmI(): Observable<IBasePerson> {
        return this.http.get<IBasePerson>(`${this.repoUrl}/me`);
    }

    public getViewableYears(): Observable<IViewableAcademicYears> {
        return this.http.get<IViewableAcademicYears>(`${this.repoUrl}/me/viewable-academic-years`);
    }

    public search(criteria: string): Observable<Array<IBasePerson>> {
        return this.http.post<Array<IBasePerson>>(`${this.repoUrl}/search`, criteria);
    }

    public getHousingInformation(applicationId: number): Observable<IHousingInformation> {
        return this.http.get<IHousingInformation>(`${this.repoUrl}/${applicationId}/housing`);
    }

    public getAcademicInformation(applicationId: number): Observable<IAcademicInformation> {
        return this.http.get<IAcademicInformation>(`${this.repoUrl}/${applicationId}/academic`);
    }

}