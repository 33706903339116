<div class="sidebar">
    <div class="app-logo">
        <!-- ICON -->
        <app-lu-icon icon="monogram" class="logo-icon"></app-lu-icon>
        <span class="logo-text">Shepherds</span>
        <span class="admin-badge">{{title}}</span>
    </div>
    <nav id="main-nav">
        <ul>
            <li class="like-h4">
                <a [routerLink]="['/dashboard/admin']" [queryParams]="{}">
                    <app-lu-icon icon="clipboard"></app-lu-icon>
                    All Applications
                </a>
            </li>
            <li class="like-h4">
                <a [routerLink]="['/dashboard/admin']" [queryParams]="{status: pendingStatusCode}">
                    <app-lu-icon icon="person"></app-lu-icon>
                    Awaiting Shepherds Review
                </a>
            </li>
            <li class="like-h4">
                <a [routerLink]="['/dashboard/admin']" [queryParams]="{status: pendingInterviewStatusCode}">
                    <app-lu-icon icon="person"></app-lu-icon>
                    Interview Requested
                </a>
            </li>
            <li class="like-h4">
                <a [routerLink]="['/dashboard/admin']" [queryParams]="{status: pendingFinalDecisionStatusCode}">
                    <app-lu-icon icon="person"></app-lu-icon>
                    Awaiting Shepherds Approval
                </a>
            </li>
            <li *ngIf="hasConfigAuthority" class="like-h4">
                <a [routerLink]="['/dashboard/admin/settings/dates']" [queryParams]="{}">
                    <app-lu-icon icon="gear"></app-lu-icon>
                    Shepherd Settings
                </a>
            </li>
        </ul>
    </nav>
    <div class="impersonate" *ngIf="this.isDeveloper || this.isImpersonating">
        <pre class="hello-text">Hello, I see you as:<br>  {{this.username}}</pre>
        <ul>
            <li class="like-h4">
                <a (click)="this.openImpersonateModal()" href="#">
                    <app-lu-icon icon="user-secret"></app-lu-icon>
                    {{this.isImpersonating ? 'Switch User' : 'Impersonate'}}
                </a>
            </li>
            <li class="like-h4" *ngIf="this.isImpersonating">
                <a (click)="this.stopImpersonating()" href="#">
                    <app-lu-icon icon="user-times"></app-lu-icon>
                    Stop Impersonating
                </a>
            </li>
        </ul>
    </div>
    <div class="logout">
        <a [href]="logoutUrl"><app-lu-icon icon="log-out"></app-lu-icon> Logout</a>
    </div>
</div>
